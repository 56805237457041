import React, { useState, useRef, useEffect } from 'react';
import io from 'socket.io-client';
import './App.css';

const socket = io('https://api.cmlinecompany.com');

const App = () => {
    const [isCameraOn, setIsCameraOn] = useState(false);
    const localVideoRef = useRef(null);
    const remoteVideoRef = useRef(null);
    const peerConnectionRef = useRef(null);
    const localStreamRef = useRef(null);

    useEffect(() => {
        if (isCameraOn) {
            navigator.mediaDevices.getUserMedia({ video: true })
                .then(stream => {
                    localVideoRef.current.srcObject = stream;
                    localStreamRef.current = stream;

                    peerConnectionRef.current = new RTCPeerConnection({
                        iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
                    });

                    stream.getTracks().forEach(track => peerConnectionRef.current.addTrack(track, stream));

                    peerConnectionRef.current.ontrack = (event) => {
                        const remoteStream = event.streams[0];
                        remoteVideoRef.current.srcObject = remoteStream;
                    };

                    peerConnectionRef.current.onicecandidate = (event) => {
                        if (event.candidate) {
                            socket.emit('signal', { candidate: event.candidate });
                        }
                    };

                    socket.on('signal', async (data) => {
                        try {
                            if (data.offer) {
                                await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(data.offer));
                                const answer = await peerConnectionRef.current.createAnswer();
                                await peerConnectionRef.current.setLocalDescription(answer);
                                socket.emit('signal', { answer });
                            } else if (data.answer) {
                                await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(data.answer));
                            } else if (data.candidate) {
                                await peerConnectionRef.current.addIceCandidate(new RTCIceCandidate(data.candidate));
                            }
                        } catch (err) {
                            console.error("Ошибка обработки сигнала:", err);
                        }
                    });
                })
                .catch(err => console.error("Ошибка доступа к камере: ", err));
        }

        return () => {
            if (localStreamRef.current) {
                localStreamRef.current.getTracks().forEach(track => track.stop());
            }
        };
    }, [isCameraOn]);

    const handleToggleCamera = () => {
        setIsCameraOn(!isCameraOn);
    };

    const handleStartStreaming = async () => {
        if (localStreamRef.current) {
            try {
                const offer = await peerConnectionRef.current.createOffer();
                await peerConnectionRef.current.setLocalDescription(offer);
                socket.emit('signal', { offer });
            } catch (err) {
                console.error("Ошибка создания предложения:", err);
            }
        }
    };

    return (
        <div className="App">
            <div className="video-container">
                <div className="local-video">
                    <video ref={localVideoRef} autoPlay muted />
                    <button onClick={handleToggleCamera}>
                        {isCameraOn ? "Выключить камеру" : "Включить камеру"}
                    </button>
                </div>
                <div className="remote-video">
                    <video ref={remoteVideoRef} autoPlay />
                    <button onClick={handleStartStreaming} disabled={!isCameraOn}>
                        Начать стрим
                    </button>
                </div>
            </div>
        </div>
    );
};

export default App;
